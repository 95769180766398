import Player from '../../elements/Name/Player'
import css from './ChatLine.module.css'

function ChatLine(props) {
  const { id, name, color, number, text = '', animal = null } = props

  return (
    <div className={css.Nickname}>
      <Player
        id={id}
        name={name}
        color={color}
        small={true}
        number={number > 0 ? number : null}
        animal={animal}
      />
      <p className={css.text}>{text}</p>
    </div>
  )
}

export default ChatLine

import css from './Player.module.css'

function Player({ id, name, color, number, small = false, animal = null }) {
  function isShiny() {
    return name === 'Paweł P.'
  }
  return (
    <div
      className={css.Player}
      aria-labelledby={id}
      style={{ backgroundColor: color }}
      data-small={small}
      data-shiny={isShiny()}
    >
      <p>
        {animal ? `${animal} ` : ''}
        {name}
      </p>
      {number && <p className={css.num}>{number}</p>}
    </div>
  )
}

export default Player

import css from './Results.module.css'
import Button from '../../elements/Button/Button'
import {
  addVoteCountsToAnswers,
  getPlayerColor,
  playerAnimal,
  playerName,
} from '../../utils'
import ChatLine from '../Nickname/ChatLine'
import Label from '../../elements/Label/Label'
import Heading from '../../elements/Heading/Heading'
import i18next from 'i18next'

function Results(props) {
  const { players, questions, reset } = props

  return (
    <div className={css.Results}>
      <Heading text={i18next.t('results.heading.all')} />

      {addVoteCountsToAnswers(questions).map((question) => (
        <section key={question.humanId} id={`QUESTION:${question.humanId}`}>
          <Label text={i18next.t('results.list.question')} underline={true} />
          <div className={css.question}>
            <ChatLine
              id={question.humanId}
              key={question.humanId}
              name={playerName(question.humanId, players)}
              color={getPlayerColor(question.humanId, players)}
              small={true}
              text={question.text}
              animal={playerAnimal(question.humanId, players)}
            />
          </div>

          <Label text={i18next.t('results.list.answers')} underline={true} />
          <div className={css.answers}>
            {question.answers.map((answer) => (
              <ChatLine
                key={answer.humanId}
                id={answer.humanId}
                name={playerName(answer.humanId, players)}
                color={getPlayerColor(answer.humanId, players)}
                text={answer.text}
                number={answer.voteCount}
                animal={playerAnimal(answer.humanId, players)}
              />
            ))}
          </div>
        </section>
      ))}

      <div className={css.wrapper}>
        <Button click={reset} text={i18next.t('results.button.reset')} />
      </div>
    </div>
  )
}

export default Results
